import axios from 'axios'
import cookies from 'components/util/cookies/url/cookies'
import AuthenticationEndpoint from './endpoints/AuthenticationEndpoint'
import TemplatesEndpoint from './endpoints/TemplatesEndpoint'
import UsersEndpoint from './endpoints/UsersEndpoint'
import AccountsEndpoint from './endpoints/AccountsEndpoint'
import RequestsEndpoint from './endpoints/RequestsEndpoint'
import PartsEndpoint from './endpoints/PartsEndpoint'
import QueueEndpoint from './endpoints/QueueEndpoint'
import BillingEndpoint from './endpoints/BillingEndpoint'
import NotificationEndpoint from './endpoints/NotificationEndpoint'

const authToken = cookies.authToken.get()
axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
axios.defaults.headers.common['X-CSRF-TOKEN'] = document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')

const endpoints = {
  authentication: AuthenticationEndpoint(axios),
  templates: TemplatesEndpoint(axios),
  users: UsersEndpoint(axios),
  accounts: AccountsEndpoint(axios),
  requests: RequestsEndpoint(axios),
  parts: PartsEndpoint(axios),
  queue: QueueEndpoint(axios),
  notification: NotificationEndpoint(axios),
  billing: BillingEndpoint(axios),
}

export default endpoints
