const BillingEndpoint = (axios) => {
  const getBillings = async (template_id) => {
      const { data } = await axios.get(`/web/billings/${template_id}`)
      return data
  }
  return {
    getBillings,
  }

  const getWeeklyBillings = async (account_id) => {
    const { data } = await axios.get(`/web/billings?account=${account_id}`)
    return data
  }
  return {
    getWeeklyBillings,
  } 

  const getMonthlyBillings = async (account_id) => {
    const { data } = await axios.get(`/web/billings?account=${account_id}&monthly=`)
    return data
  }
  return {
    getMonthlyBillings,
  } 
}

export default BillingEndpoint