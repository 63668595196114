import React, { useState, useEffect, useCallback } from 'react'
import { Button, Card, Row, Col, Table } from 'antd'
import apiClient from 'components/lib/api/apiClient'
import { Helmet } from 'react-helmet'

const BillingRollupPage = () => {
  const [billingRollup, setBillingRollup] = useState({})
  const [count, setCount] = useState(0)

  const columns = [
    { title: 'PeriodStart', dataIndex: 'periodStart', key: 'periodStart' },
    { title: 'PeriodEnd', dataIndex: 'periodEnd', key: 'periodEnd' },
    { title: 'Increment', dataIndex: 'increment', key: 'increment'},
    { title: 'Count', dataIndex: 'count', key: 'count' },
    { title: 'Statistics', dataIndex: 'statistics', key: 'statistics' },
  ]

  const getData = useCallback(async () => {
    try {
      const response = await apiClient.billing.getMonthlyBillings()
      setBillingRollup(response.data)
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <div>
      <Helmet>
        <title>Billing Rollup</title>
      </Helmet>
      <Row gutter={[16, 16]}>
        <Col span={24}>
          <Card title="BillingRollup" extra={<Button onClick={getData}>Refresh</Button>}>
            <Table dataSource={billingRollup} columns={columns} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}

export default BillingPage